import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CommonTable from "../table";
import theme from "../../theme";
import { getCompanyPaymentsAction } from "../../containers/companyDashboard/action";
import { moneyValue } from "../../utils";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {
    margin: "0 auto",
    backgroundColor: theme.colors.White,
    width: "100%",
    height: "100vh"
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
    textAlign: "center",
  },
  headerBody: {
    fontSize: "13px",
    textAlign: "center",
  },
  Tab: {
    position: "relative",
    top: "31px",
    left: "-22px",
    cursor: "pointer",
    width: "10px", // Increase the width to make it wider
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px",
      top: `50%`,
      left: `1004%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
      textAlign: "center", // Make the title text centered
    },
  },
  currency: {
    // fontSize: "8px",
    position: "absolute",
    // top: "0.2px",
    padding: "55px",
    marginLeft: "220px",
    marginRight: "300px"
    // borderRadius: "14px",
  },
  input: {
    border: '1px solid #ccc',
    borderRadius: '14px',
    // padding: theme.spacing(1, 2),
    outline: 'none',
    '&:focus': {
      borderColor: theme.colors.DodgerBlue,
    },
    color: theme.colors.DodgerBlue,
    fontWeight: 'bold',
    borderColor: theme.colors.DodgerBlue

  },
  buttonContainer: {
    position: "relative",
  },
  button1: {
    backgroundColor: 'white',
    position: "absolute",
    marginRight: "-175px",
    marginLeft: "193px",
    marginTop: "5px",
    marginBottom: "-500px",
    borderRadius: '14px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.colors.DodgerBlue,
    },
    borderColor: theme.colors.DodgerBlue
  },
  button2: {
    backgroundColor: 'white',
    position: "absolute",
    marginLeft: "282px",
    marginTop: "5px",
    borderRadius: '14px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.colors.DodgerBlue,
    },
    borderColor: theme.colors.DodgerBlue
  },
  tube: {
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    textAlign: "center",
    padding: "5px 10px",
  },
}));

function SanaamPayment() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyDashboardReducer = useSelector(state => state.companyDashboardReducer)
  const getCompanyPaymentActionMethod = React.useCallback((data) => getCompanyPaymentsAction(data));

  const [page, setPage] = React.useState(1)
  const totalPage = Math.ceil(companyDashboardReducer?.companyPaymentList?.data?.results?.length / 10);
  React.useEffect(() => {
    // !(companyDashboardReducer?.companyPaymentList) ? !
    dispatch(getCompanyPaymentActionMethod({ "page": page, "page_size": 10 }))
  }, [page])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.currency}>
          <div className={classes.Tab}>
            <img src={"/images/Tab2.svg"} alt="Sanaam Payments"></img>
            <span className="tableTitle">Sanaam Payments</span>
          </div>
          {companyDashboardReducer?.error?.length ? (
            <div className={"error-container"}>
              {companyDashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>
          ) : null}
          {companyDashboardReducer?.companyListError?.length ? (
            <div className={"error-container"}>
              {companyDashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>
          ) : null}
          <>
            <CommonTable
              columns={[
                {
                  Header: "Amount",
                  accessor: "amount",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data, row) => {
                    return (
                      <div className={classes.headerBody}>
                        <sup>{row?.company?.currency}</sup>
                        {moneyValue(data)}
                        {/* {row.employee.employee_id} */}
                      </div>
                    );
                  },
                },
                {
                  Header: "Company Name",
                  accessor: "company",
                  HeaderCell: (data) => {
                    return (<div className={classes.headerName}>{data}</div>)
                  },
                  Cell: (data, row) => {
                    return (
                      <div className={classes.headerBody}>
                        {data?.name}
                      </div>)
                  }
                },
                {
                  Header: "Created On",
                  accessor: "created_at",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data, row) => {
                    return (<div className={classes.headerBody}>{data ? moment(data).format("DD/MM/YYYY LT") : "N/A"}</div>)
                  },
                },
                {
                  Header: "Settled On",
                  accessor: "settled_at",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data, row) => {
                    return (<div className={classes.headerBody}>{data ? moment(data).format("DD/MM/YYYY LT") : "N/A"}</div>)
                  }
                },
                {
                  Header: "State",
                  accessor: "state",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>;
                  },
                  Cell: (data, row) => {
                    return (<div className={classes.headerBody}>{data}</div>)
                  }
                },
                {
                  Header: "Paystack Reference",
                  accessor: "id",
                  HeaderCell: (data) => {
                    return <div className={classes.headerName}>{data}</div>
                  },
                  Cell: (data, row) => {
                    return (<div className={classes.headerBody}>{data}</div>)
                  }
                },
                {
                  Header: "Paystack Channel",
                  accessor: "payment_channel",
                  HeaderCell: (data) => { return <div className={classes.headerName}>{data}</div> },
                  Cell: (data, row) => {
                    return (<div className={classes.headerBody}>{data}</div>)
                  }
                }
              ]}
              data={companyDashboardReducer?.companyPaymentList?.data?.results}
            />
          </>
          {companyDashboardReducer.companyPaymentList?.data?.results?.length ? (
            <div className={"pagination"}>
              <Pagination
                page={page}
                count={totalPage}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}


export default SanaamPayment;