import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordAction } from "../login/action";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import theme from "../../theme";
import history from "../../history";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles((innertheme) => ({
  root: {
    margin: "50px 0px",
    [innertheme.breakpoints.up("sm")]: {
      margin: "0px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
  },
  logo: { width: "100px", height: "76px" },
  welcomeText: {
    marginTop: "10px",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
  },
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "80%",
    [innertheme.breakpoints.up("sm")]: {
      width: "370px",
    },
    "& .back-btn": {
      display: "none",
      marginTop: "10px",
      border: "none",
      [innertheme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  bottomContainer: { color: theme.colors.Violet, fontSize: "16px" },
  link: {
    margin: "0px 10px",
    color: theme.colors.Violet,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO, textAlign: "center" },
}));

function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const forgotPasswordMethod = (data) => dispatch(forgotPasswordAction(data));

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [email, setEmail] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [methodCalled, setMethodCalled] = useState(false);

  useEffect(() => {
    if (loginReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [loginReducer]);

  return (
    <Container>
      <div className={classes.root}>
        <Helmet>
          <title>{"Sanaam - Forgot password"}</title>
          <meta property="og:title" content={"Sanaam - Forgot password"} />
        </Helmet>
        <div className={classes.flexContainer}>
          <img className={classes.logo} src="/images/logo.svg" alt="logo"></img>
        </div>
        <div className={`${classes.flexContainer} ${classes.welcomeText}`}>
          Welcome to Sanaam!
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.formContainer}>
            {methodCalled ? (
              loginReducer?.error?.length ? (
                <div className={"error-container"}>
                  {loginReducer.error.map((each, index) => (
                    <div key={index}>{each}</div>
                  ))}
                </div>
              ) : loginReducer?.forgotPassSuccess ? (
                <div className={"success-container"}>
                  {"Reset password link successfully sent."}
                </div>
              ) : null
            ) : null}
            <div className={classes.formTitle}>Forgot password</div>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsValidated(true);
                if (email && email.match(Constants.email_regex)) {
                  setMethodCalled(true);
                  forgotPasswordMethod({
                    email,
                  });
                }
              }}
            >
              <CommonInput
                label="Email"
                placeholder="Enter email"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
                error={
                  isValidated &&
                  (!email || (email && !email.match(Constants.email_regex)))
                }
                helperText={
                  isValidated &&
                  ((!email && "Email may not be blank.") ||
                    (email &&
                      !email.match(Constants.email_regex) &&
                      "Email is invalid."))
                }
                disabled={loginReducer.loading}
              />
              <CommonButton
                type="submit"
                text="Submit"
                stylevariant="secondary1"
                style={{ marginTop: "10px" }}
                disabled={loginReducer.loading}
                loading={loginReducer.loading}
              />
              <CommonButton
                type="button"
                text="Back"
                stylevariant="secondary2"
                className="back-btn"
                onClick={() => {
                  history.push("/");
                }}
              />
            </form>
          </div>
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.bottomContainer}>
            <div className={classes.flexContainer}>
              <a className={classes.link} href={window.location.origin}>
                About
              </a>
              <a className={classes.link} href={window.location.origin}>
                Contact us
              </a>
            </div>
            <div className={classes.copyright}>
              Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ForgotPassword;
