import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { LoginAction } from "./action";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import theme from "../../theme";
import history from "../../history";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles((innertheme) => ({
  root: {
    margin: "50px 0px",
    [innertheme.breakpoints.up("sm")]: {
      margin: "0px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
  },
  logo: { width: "100px", height: "76px" },
  welcomeText: {
    marginTop: "10px",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
  },
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "80%",
    [innertheme.breakpoints.up("sm")]: {
      width: "370px",
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  bottomContainer: { color: theme.colors.Violet, fontSize: "16px" },
  link: {
    margin: "0px 10px",
    color: theme.colors.Violet,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO, textAlign: "center" },
  forgotPassContainer: {
    fontFamily: theme.fontFamily.MARKPRO,
    color: theme.colors.Violet,
    fontSize: "12px",
    textAlign: "right",
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" },
  },
}));

function Login({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const LoginMethod = (data, redirectURI) =>
    dispatch(LoginAction(data, redirectURI));

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectURI, setRedirectURI] = useState("");
  const [methodCalled, setMethodCalled] = useState(false);

  useEffect(() => {
    if (location?.search) {
      const { redirectURI } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      setRedirectURI(redirectURI);
    }
  }, [location]);

  useEffect(() => {
    const { loginError, loginSuccess } = loginReducer;
    if (loginSuccess) {
      setErrorMessage(null);
    } else if (loginError) {
      setErrorMessage(loginError);
    }
  }, [loginReducer]);

  useEffect(() => {
    if (errorMessage?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [errorMessage]);

  return (
    <Container>
      <div className={classes.root}>
        <Helmet>
          <title>{"Sanaam - Login"}</title>
          <meta property="og:title" content={"Sanaam - Login"} />
        </Helmet>
        <div className={classes.flexContainer}>
          <img className={classes.logo} src="/images/logo.svg" alt="logo"></img>
        </div>
        <div className={`${classes.flexContainer} ${classes.welcomeText}`}>
          Welcome to Sanaam!
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.formContainer}>
            {methodCalled && errorMessage?.length ? (
              <div className={"error-container"}>
                {errorMessage.map((each, index) => (
                  <div key={index}>{each}</div>
                ))}
              </div>
            ) : null}
            <div className={classes.formTitle}>Login</div>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsValidated(true);
                setErrorMessage(null);
                if (
                  email &&
                  password &&
                  email.match(Constants.email_regex) &&
                  password.match(Constants.password_regex)
                ) {
                  setMethodCalled(true);
                  LoginMethod(
                    {
                      email: email,
                      password: password,
                    },
                    "/backoffice/dashboard/overview"
                  );
                }
              }}
            >
              <CommonInput
                label="Email"
                placeholder="Enter email"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
                error={
                  isValidated &&
                  (!email || (email && !email.match(Constants.email_regex)))
                }
                helperText={
                  isValidated &&
                  ((!email && "Email may not be blank.") ||
                    (email &&
                      !email.match(Constants.email_regex) &&
                      "Email is invalid."))
                }
                disabled={loginReducer.loading}
              />
              <CommonInput
                label="Password"
                placeholder="Enter password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={
                  isValidated &&
                  (!password ||
                    (password && !password.match(Constants.password_regex)))
                }
                helperText={
                  isValidated &&
                  ((!password && "Password may not be blank.") ||
                    (password &&
                      !password.match(Constants.password_regex) &&
                      "Password is invalid."))
                }
                disabled={loginReducer.loading}
              />

              <div
                className={classes.forgotPassContainer}
                onClick={() => {
                  history.push("/auth/forgot-password");
                }}
              >
                Forgot password?
              </div>

              <CommonButton
                type="submit"
                text="Submit"
                stylevariant="secondary1"
                style={{ marginTop: "10px" }}
                disabled={loginReducer.loading}
                loading={loginReducer.loading}
              />
            </form>
          </div>
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.bottomContainer}>
            <div className={classes.flexContainer}>
              <a className={classes.link} href={window.location.origin}>
                About
              </a>
              <a className={classes.link} href={window.location.origin}>
                Contact us
              </a>
            </div>
            <div className={classes.copyright}>
              Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Login;
