import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import {
  getCountriesAction,
  getCurrenciesAction,
  getTimeZonesAction,
} from "../login/action";
import Loading from "../../components/loading";
import CompanyRegistrationStep1 from "./step1";
import CompanyRegistrationStep2 from "./step2";
import CompanyRegistrationStep3 from "./step3";
import CompanyRegistrationStep4 from "./step4";
import {
  onBoardCompanyInfoAction,
  onBoardCompanyVerifyAction,
  getFeesSettingsAction,
} from "./action";

const useStyles = makeStyles((innertheme) => ({
  root: {
    margin: "50px 0px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: { width: "100px", height: "76px" },
  welcomeText: {
    margin: "20px",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: theme.fontFamily.MARKPROLIGHT,
  },
  bottomContainer: { color: theme.colors.Violet, fontSize: "16px" },
  link: {
    margin: "0px 10px",
    color: theme.colors.Violet,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [innertheme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    "& .tab": {
      display: "flex",
      alignItems: "center",
      margin: "10px 30px",
      // cursor: "pointer",
    },
    "& .stepNumber": {
      marginRight: "10px",
      fontSize: "20px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      padding: "5px",
      borderRadius: "50%",
      height: "20px",
      width: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .stepLabel": {
      marginLeft: "10px",
      fontSize: "20px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
    },
  },
}));

function ComapnyRegistration({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCountriesMethod = useCallback(
    () => dispatch(getCountriesAction()),
    [dispatch]
  );
  const getCurrenciesMethod = useCallback(
    () => dispatch(getCurrenciesAction()),
    [dispatch]
  );
  const getTimeZonesMethod = useCallback(
    () => dispatch(getTimeZonesAction()),
    [dispatch]
  );
  const onBoardCompanyInfoMethod = useCallback(
    (cid) => dispatch(onBoardCompanyInfoAction(cid)),
    [dispatch]
  );
  const onBoardCompanyVerifyMethod = useCallback(
    (data) => dispatch(onBoardCompanyVerifyAction(data)),
    [dispatch]
  );
  const getFeesSettingsMethod = useCallback(
    (country) => dispatch(getFeesSettingsAction(country)),
    [dispatch]
  );

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const onBoardCompanyReducer = useSelector((state) => {
    return state.onBoardCompanyReducer;
  });

  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [totalEmployees, setTotalEmployees] = useState("");
  const [file, setFile] = useState(null);
  const [salaryDays, setSalaryDays] = useState(null);
  const [workingDays, setWorkingDays] = useState(null);
  // const [daysDesignated, setDaysDesignated] = useState("");
  const [employeeWithdrawalLimit, setEmployeeWithdrawalLimit] = useState(70);
  const [timeZone, setTimeZone] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [country, setCountry] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    getCountriesMethod();
    getCurrenciesMethod();
    getTimeZonesMethod();
  }, [getCountriesMethod, getCurrenciesMethod, getTimeZonesMethod]);

  useEffect(() => {
    if (match?.params?.cid) {
      onBoardCompanyInfoMethod(match.params.cid);
    }
  }, [match, onBoardCompanyInfoMethod]);

  useEffect(() => {
    const { onBoardCompanyInfo, success, error } = onBoardCompanyReducer;
    if (error) {
      window.scrollTo(0, 0);
    }
    if (success && !error) {
      setStep(4);
    }
    if (onBoardCompanyInfo?.name && !companyName) {
      setCompanyName(onBoardCompanyInfo.name);
    }
    if (onBoardCompanyInfo?.admins?.length) {
      if (onBoardCompanyInfo.admins[0].first_name) {
        setFirstName(onBoardCompanyInfo.admins[0].first_name);
      }
      if (onBoardCompanyInfo.admins[0].last_name) {
        setLastName(onBoardCompanyInfo.admins[0].last_name);
      }
      if (onBoardCompanyInfo.admins[0].email) {
        setEmail(onBoardCompanyInfo.admins[0].email);
      }
    }
  }, [onBoardCompanyReducer]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Comapny Registration"}</title>
        <meta property="og:title" content={"Sanaam - Comapny Registration"} />
      </Helmet>
      <Loading
        showLoader={onBoardCompanyReducer?.loading || loginReducer?.loading}
      />
      <div className={classes.flexContainer}>
        <img className={classes.logo} src="/images/logo.svg" alt="logo"></img>
      </div>
      <div className={`${classes.flexContainer} ${classes.welcomeText}`}>
        Welcome! Create your Sanaam company account
      </div>
      <div className={classes.tabContainer}>
        {[
          "Company Information",
          "Set Up Working Days",
          "Company Main Contact",
        ].map((each, index) => {
          return (
            <div
              key={index}
              className="tab"
              style={{
                opacity: step >= index + 1 ? 1 : 0.5,
                color: step > index + 1 ? "#31DB65" : theme.colors.DodgerBlue,
              }}
            >
              <div
                className="stepNumber"
                style={{
                  backgroundColor: step > index + 1 ? "#99F1A75A" : "#ECF3FF",
                  border:
                    step > index + 1
                      ? "1px solid #99F1A7"
                      : "1px solid #BDD4FC",
                }}
              >
                {step > index + 1 ? (
                  <img
                    src="/images/check.png"
                    alt="check"
                    height={15}
                    width={15}
                  />
                ) : (
                  index + 1
                )}
              </div>
              <div className="stepLabel">{each}</div>
            </div>
          );
        })}
      </div>
      <div
        className={classes.flexContainer}
        style={{ marginTop: "25px", marginBottom: "50px" }}
      >
        {step === 1 ? (
          <CompanyRegistrationStep1
            setStep={setStep}
            companyName={companyName}
            setCompanyName={setCompanyName}
            totalEmployees={totalEmployees}
            setTotalEmployees={setTotalEmployees}
            file={file}
            setFile={setFile}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
            errors={onBoardCompanyReducer?.error}
          />
        ) : step === 2 ? (
          <CompanyRegistrationStep2
            getFeesSettingsMethod={getFeesSettingsMethod}
            setStep={setStep}
            salaryDays={salaryDays}
            setSalaryDays={setSalaryDays}
            workingDays={workingDays}
            setWorkingDays={setWorkingDays}
            // daysDesignated={daysDesignated}
            // setDaysDesignated={setDaysDesignated}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            currency={currency}
            setCurrency={setCurrency}
            country={country}
            setCountry={setCountry}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
            errors={onBoardCompanyReducer?.error}
          />
        ) : step === 3 ? (
          <CompanyRegistrationStep3
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            gender={gender}
            setGender={setGender}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            employeeWithdrawalLimit={employeeWithdrawalLimit}
            setEmployeeWithdrawalLimit={setEmployeeWithdrawalLimit}
            setConfirmPassword={setConfirmPassword}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
            errors={onBoardCompanyReducer?.error}
            onBoardCompanyVerifyMethod={() => {
              let formData = {
                cid: match.params.cid,
                data: {
                  name: companyName,
                  country: country?.value,
                  currency: currency?.value,
                  timezone: timeZone?.value,
                  employee_withdrawal_limit: employeeWithdrawalLimit,
                  formula: salaryDays?.value,
                  image: null,
                  total_employees: parseInt(totalEmployees, 10),
                  admins: [
                    {
                      email: email,
                      first_name: firstName,
                      last_name: lastName,
                      gender: gender?.value,
                      password: password,
                      profile: {
                        phone: phoneNumber,
                      },
                    },
                  ],
                  fees_settings: {
                    id: currency?.id,
                    country: currency?.country,
                    currency: currency?.value,
                  },
                },
              };
              if (workingDays?.length) {
                formData["data"]["workdays"] = workingDays.map(
                  (each) => each.value
                );
              }
              if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  formData["data"]["image"] = reader.result;
                  onBoardCompanyVerifyMethod(formData);
                };
                reader.onerror = function (error) {
                  console.log("Error: ", error);
                };
              } else {
                onBoardCompanyVerifyMethod(formData);
              }
            }}
          />
        ) : (
          <CompanyRegistrationStep4 />
        )}
      </div>
      <div className={classes.flexContainer}>
        <div className={classes.bottomContainer}>
          <div className={classes.flexContainer}>
            <a className={classes.link} href={window.location.origin}>
              About
            </a>
            <a className={classes.link} href={window.location.origin}>
              Contact us
            </a>
          </div>
          <div className={classes.copyright}>
            Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComapnyRegistration;
