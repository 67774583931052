import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import CommonSwitch from "../switch";
import theme from "../../theme";
import CommonTable from "../table";
import history from "../../history";
import ConfirmSwitchModal from "../confirmSwitchModal";
import CommonButton from "../button";
import { inviteEmployeesAction } from "../../containers/companyDashboard/action";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  headerName: {
    color: theme.colors.Violet,
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
  },
  employeeName: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  bankName: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  adminTube: {
    color: "#22D559",
    backgroundColor: "#E4FDEC",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "10px",
    padding: "3px 5px",
  },
}));

function EmployeeList({
  companyInformation,
  data,
  page,
  setPage,
  totalPage,
  updateEmployeeMethod,
  employeeState=null,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });
  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [confirmSwitch, setConfirmSwitch] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [switchRecord, setSwitchRecord] = useState(null);
  const [switchMethod, setSwitchMethod] = useState(null);
  const [invitedEmployees, setInvitedEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const isEmployeeChecked = (employee) => {
    return selectedEmployees.indexOf(employee.id) > -1;
  };

  const toggleEmployeeSelection = (employee) => {
    if (isEmployeeChecked(employee)) {
      setSelectedEmployees(
        selectedEmployees.filter((eid) => eid !== employee.id)
      );
    } else {
      setSelectedEmployees([...selectedEmployees, employee.id]);
    }
  };

  const shouldInvite = (employee) =>
    employee.state === "PENDING" && !invitedEmployees.includes(employee.id);

  const isAllEmployeeChecked = () => {
    const employees_list = data || [];
    const possibleCount = employees_list.filter(shouldInvite).length;

    return (
      selectedEmployees?.length && selectedEmployees?.length === possibleCount
    );
  };

  const selectAllEmployees = () => {
    if (isAllEmployeeChecked()) {
      setSelectedEmployees([]);
    } else {
      const employees_list = data || [];
      setSelectedEmployees([
        ...employees_list.filter(shouldInvite).map((e) => e.id),
      ]);
    }
  };

  const onBulkInviteCLick = () => {
    dispatch(inviteEmployeesAction(selectedEmployees));
  };

  const onInviteCLick = (employee) => {
    dispatch(inviteEmployeesAction([employee.id]));
  };

  useEffect(() => {
    if (data && invitedEmployees.length === 0) {
      setInvitedEmployees(
        ...invitedEmployees,
        data.filter((e) => e.state !== "PENDING").map((e) => e.id)
      );
    }
  }, [data]);

  useEffect(() => {
    const { inviteSuccess } = companyDashboardReducer;
    if (inviteSuccess) {
      setInvitedEmployees([...invitedEmployees, ...inviteSuccess.data]);
      setSelectedEmployees([]);
    }

    if (
      dashboardReducer?.error?.length ||
      companyDashboardReducer?.error?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer, companyDashboardReducer]);

  useEffect(() => {
    if (
      confirmation === "yes" &&
      switchRecord &&
      switchRecord.id &&
      switchMethod
    ) {
      if (switchMethod === "updateEmployeeMethod")
        updateEmployeeMethod(
          {
            is_active: !switchRecord.is_active,
          },
          switchRecord.id,
          employeeState
        );
      setSwitchRecord(null);
      setSwitchMethod(null);
      setConfirmation(null);
    }
  }, [confirmation]);

  return (
    <div className="tableContainer">
      {dashboardReducer?.error?.length ||
      companyDashboardReducer?.error?.length ? (
        <div className={"error-container"} style={{ float: "left" }}>
          {dashboardReducer?.error?.length
            ? dashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))
            : null}
          {companyDashboardReducer?.error?.length
            ? companyDashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))
            : null}
        </div>
      ) : null}
      <CommonTable
        fullRadius={true}
        columns={[
          {
            accessor: "id",
            HeaderCell: (data) => {
              return (
                <div>
                  <input
                    type="checkbox"
                    checked={isAllEmployeeChecked()}
                    onChange={selectAllEmployees}
                  />
                </div>
              );
            },
            Cell: (data, record) => {
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  {!invitedEmployees.includes(record.id) && (
                    <input
                      type="checkbox"
                      disabled={!updateEmployeeMethod}
                      checked={isEmployeeChecked(record)}
                      onChange={() => toggleEmployeeSelection(record)}
                    />
                  )}
                </div>
              );
            },
          },
          {
            Header: "Employee Name",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record, index) => {
              return (
                <div className={classes.employeeName}>
                  {record?.first_name} {record?.last_name}{" "}
                  {record?.is_admin ? (
                    <span className={classes.adminTube}>Admin</span>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          {
            Header: "Bank Name",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <div className={classes.bankName}>
                  {record?.banks?.length ? record.banks[0].name : ""}
                </div>
              );
            },
          },
          {
            Header: "Net Monthly Salary",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">
                    {companyInformation?.currency}
                  </span>{" "}
                  {moneyValue(record?.profile?.salary)}
                </div>
              );
            },
          },
          {
            Header: "Total Withdrawn",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <div className={classes.salary}>
                  <span className="currency">
                    {companyInformation?.currency}
                  </span>{" "}
                  {moneyValue(record?.profile?.withdrawals)}
                </div>
              );
            },
          },
          // {
          //   Header: "Leaves",
          //   accessor: "id",
          //   HeaderCell: (data) => {
          //     return <div className={classes.headerName}>{data}</div>;
          //   },
          //   Cell: (data, record) => {
          //     return (
          //       <div className={classes.salary}>
          //         <span className="currency">
          //           {companyInformation?.currency}
          //         </span>{" "}
          //         {record?.profile?.leaves
          //           ? Math.round(record?.profile?.leaves * 1000) / 1000
          //           : 0}
          //       </div>
          //     );
          //   },
          // },
          // {
          //   Header: "Deductions",
          //   accessor: "id",
          //   HeaderCell: (data) => {
          //     return <div className={classes.headerName}>{data}</div>;
          //   },
          //   Cell: (data, record) => {
          //     return (
          //       <div className={classes.salary}>
          //         <span className="currency">
          //           {companyInformation?.currency}
          //         </span>{" "}
          //         {record?.profile?.deductions
          //           ? Math.round(record?.profile?.deductions * 1000) / 1000
          //           : 0}
          //       </div>
          //     );
          //   },
          // },
          {
            Header: "Remaining Balance",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <div
                  className={classes.salary}
                  style={{ fontFamily: theme.fontFamily.MARKPROHEAVY }}
                >
                  <span className="currency">
                    {companyInformation?.currency}
                  </span>{" "}
                  {moneyValue(record?.profile?.balance)}
                </div>
              );
            },
          },
          {
            Header: "Account Status",
            accessor: "id",
            HeaderCell: (data) => {
              return <div className={classes.headerName}>{data}</div>;
            },
            Cell: (data, record) => {
              return (
                <CommonSwitch
                  name={data}
                  checked={record?.is_active}
                  disabled={!updateEmployeeMethod}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmSwitch(true);
                    setSwitchRecord(record);
                    setSwitchMethod("updateEmployeeMethod");
                  }}
                />
              );
            },
          },
          {
            accessor: "id",
            HeaderCell: (data) => {
              return (
                <div className={classes.inviteAction}>
                  <CommonButton
                    type="button"
                    text="Bulk Invite"
                    stylevariant="secondary2"
                    onClick={(e) => {
                      e.stopPropagation();
                      onBulkInviteCLick(selectedEmployees);
                    }}
                    disabled={!selectedEmployees.length}
                    style={{
                      flex: 0.5,
                      height: "28px",
                    }}
                    fontSize="10px"
                    fontFamily={theme.fontFamily.MARKPROBOLD}
                  />
                </div>
              );
            },
            Cell: (data, record, index) => {
              return (
                <div className={classes.withdrawAction}>
                  <CommonButton
                    type="button"
                    text="Invite"
                    stylevariant="secondary3"
                    onClick={(e) => {
                      e.stopPropagation();
                      onInviteCLick(record);
                    }}
                    disabled={invitedEmployees.includes(record.id)}
                    style={{
                      flex: 0.5,
                      height: "28px",
                    }}
                    fontSize="10px"
                    fontFamily={theme.fontFamily.MARKPROBOLD}
                  />
                </div>
              );
            },
          },
        ]}
        data={data}
        onRowClick={(rowIndex) => {
          const record = data[rowIndex];
          history.push(`/company/dashboard/Employees?employeeId=${record?.id}`);
        }}
      />
      {data?.length ? (
        <div className={"pagination"}>
          <Pagination
            page={page}
            count={totalPage}
            showFirstButton
            showLastButton
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </div>
      ) : null}
      <ConfirmSwitchModal
        openModal={confirmSwitch}
        setConfirmation={(confirmation) => {
          setConfirmSwitch((temp) => !temp);
          setConfirmation(confirmation);
        }}
      />
    </div>
  );
}

export default EmployeeList;
