import CommonTable from "../table";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import CommonButton from "../button";
import AlertDialog from "../dialog/alert";
import { Pagination } from "@material-ui/lab";
import { getCleanMonth, getIntentDisplay, moneyValue } from "../../utils";
import { declinePaymentAction, getPaymentListAction } from "../../containers/dashboard/action";
import history from "../../history";

const useStyles = makeStyles(() => ({
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  withdrawAction: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "19px",
      width: "19px",
      filter: "brightness(0) invert(0)",
      cursor: "pointer",
    },
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
  },
  button: {
    marginLeft: "10px",
    padding: "5px 10px",
    fontSize: "12px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  },
  settledButton: {
    backgroundColor: theme.colors.DodgerBlue,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colors.Violet,
    },
  },
  declineButton: {
    backgroundColor: theme.colors.Black,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colors.MintGreen,
    },
  },
  srOnly: {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0,
  },
}));

const AdminTransactions = () => {
  const classes = useStyles();
  const getPaymentListActionMethod = useCallback(data => getPaymentListAction(data));
  const declinePaymentActionMethod = useCallback((data, payment_id) => declinePaymentAction(data, payment_id))
  const [searchTerm, setSearchTerm] = useState("PENDING");
  const [transactionHistoryFilters, setTransactionHistoryFilters] = useState({});
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const [declinePaymentId, setDeclinePaymentId] = useState("")
  const [defaultData, setDefaultData] = useState({ page: 1, page_size: 5, intent: "WITHDRAW", history: "true" })
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboardReducer);

  const handleSettle = (transactionId) => {
    history.push(`/payments/${transactionId}`);
  };
  const handleDecline = (transactionId) => {
    console.log("Declining transaction with ID:", transactionId);
    setOpenAlertDialog(true)
    setDeclinePaymentId(transactionId)
  };

  const onSearchCancel = () => {
    setSearchTerm("")
    dispatch(getPaymentListActionMethod({ search: "", ...defaultData }))
  };

  useEffect(() => {
    if (searchTerm) {
      dispatch(getPaymentListActionMethod({ search: searchTerm, ...defaultData }))
    } else {
      dispatch(getPaymentListActionMethod(defaultData))
    }
  }, [defaultData])
  return (
    <>
      <div style={{ width: "80%", marginLeft: "18%", marginTop: "20px" }}>
        {dashboardReducer?.error?.length ? (
          <div className={"error-container"}>
            {dashboardReducer?.error?.length
              ? dashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))
              : null}
          </div>
        ) : null}
        {/* {dashboardReducer?.error ? (
          <div className={"error-container"}>
            {dashboardReducer?.error}
          </div>
        ) : null} */}
        <AlertDialog
          handleAccept={() => {
            dispatch(declinePaymentActionMethod({}, declinePaymentId));
            setOpenAlertDialog(false);
          }}
          description=""
          title="Are you sure you want to decline this pending payment ?"
          open={openAlertDialog}
          setOpen={setOpenAlertDialog}
        />
        <CommonTable
          search={{
            term: searchTerm,
            placeholder: "Search by name or state",
            onChange: (e) => setSearchTerm(e.target.value),
            onSubmit: () => dispatch(getPaymentListActionMethod({ search: searchTerm, ...defaultData })),
            onCancel: onSearchCancel,
          }}
          columns={[
            {
              Header: "Employee Name",
              accessor: "employee",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => <div>{data?.name}</div>,
            },
            {
              Header: "Intent",
              accessor: "intent",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data) => getIntentDisplay(data),
            },
            {
              Header: "State",
              accessor: "state",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => <div>{row?.state}</div>,
            },
            {
              Header: "Created At",
              accessor: "created_at",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div>{new Date(row?.created_at).toLocaleDateString()}</div>
              ),
            },
            {
              Header: "Settled At",
              accessor: "settled_at",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div>
                  {row?.settled_at
                    ? new Date(row?.settled_at).toLocaleDateString()
                    : "----------"}
                </div>
              ),
            },
            {
              Header: "Declined At",
              accessor: "declined_at",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div>
                  {row?.declined_at
                    ? new Date(row?.declined_at).toLocaleDateString()
                    : "----------"}
                </div>
              ),
            },
            {
              Header: "Net",
              accessor: "net",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div className={classes.salary}>
                  <span className="currency">{row?.company?.currency}</span>
                  {moneyValue(row?.net)}
                </div>
              ),
            },
            {
              Header: "Fees",
              accessor: "fees",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div className={classes.salary}>
                  <span className="currency">{row?.company?.currency}</span>
                  {moneyValue(row?.fees)}
                </div>
              ),
            },
            {
              Header: "Total",
              accessor: "total",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => (
                <div className={classes.salary}>
                  <span className="currency">{row?.company?.currency}</span>
                  {moneyValue(row?.total)}
                </div>
              ),
            },
            {
              Header: "Actions",
              accessor: "state",
              HeaderCell: (data) => (
                <div className={classes.headerName}>{data}</div>
              ),
              Cell: (data, row) => {
                return (
                  <div>
                    <CommonButton
                      type="button"
                      stylevariant="secondary1"
                      text="Settle"
                      fontSize="12px"
                      style={{ width: "10px", marginLeft: "5px" }}
                      onClick={() => handleSettle(row?.id)}
                      disabled={data !== "SETTLED" && data !== "DECLINE" ? false : true}
                    />
                    <CommonButton
                      type="button"
                      stylevariant="secondary2"
                      text="Decline"
                      fontSize="12px"
                      style={{ width: "10px", marginLeft: "5px" }}
                      onClick={() => handleDecline(row?.id)}
                      disabled={data !== "SETTLED" && data !== "DECLINE" ? false : true}
                    />
                  </div>
                );
              }
            }
          ]}
          data={dashboardReducer?.paymentList?.data || []}
        />
        {dashboardReducer?.paymentList?.data?.length ? (
          <div className={"pagination"}>
            <Pagination
              page={defaultData?.page}
              count={
                Math.ceil(
                  (dashboardReducer?.paymentList?.count || 0) / defaultData?.page_size
                )
              }
              showFirstButton
              showLastButton
              onChange={(event, value) => {
                setDefaultData({ ...defaultData, page: value })
              }}
            />
          </div>
        ) : null}
      </div>

    </>
  );
};

export default AdminTransactions;
