import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles((innertheme) => ({
  root: {
    backgroundColor: theme.colors.DodgerBlue,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    [innertheme.breakpoints.up("sm")]: {
      height: "100vh",
      overflow: "hidden",
    },
  },
  mainContainer: {
    zIndex: 2,
    [innertheme.breakpoints.up("sm")]: {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
    },
  },
  container: {
    display: "block",
    padding: "20px",
    [innertheme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "0px",
    },
  },
  left: {
    marginRight: "0px",
    width: "auto",
    [innertheme.breakpoints.up("sm")]: {
      marginRight: "50px",
      width: "450px",
    },
  },
  logoContainer: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [innertheme.breakpoints.up("sm")]: {
      display: "block",
    },
    "& img": {
      height: "76px",
      width: "100px",
    },
  },
  mainTitle: {
    color: theme.colors.White,
    fontSize: "60px",
    fontFamily: theme.fontFamily.MARKPROBLACK,
    marginBottom: "20px",
    position: "relative",
    zIndex: 2,
    textAlign: "center",
    [innertheme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  cardContainer: {
    backgroundColor: theme.colors.White,
    borderRadius: "15px",
    textAlign: "center",
    position: "static",
    zIndex: 2,
    padding: "10px",
    width: "auto",
    [innertheme.breakpoints.up("sm")]: {
      padding: "50px",
      width: "450px",
    },
  },
  bingoImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
  },
  titleText: {
    color: theme.colors.Violet,
    fontSize: "30px",
    fontFamily: theme.fontFamily.MARKPRO,
  },
  buttonContainer: {
    "& img": { cursor: "pointer" },
    marginTop: "30px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerBottomContainer: {
    color: theme.colors.White,
    fontSize: "16px",
    margin: "20px",
    textAlign: "center",
    display: "block",
    [innertheme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  bottomContainer: {
    color: theme.colors.White,
    fontSize: "16px",
    position: "absolute",
    bottom: 20,
    display: "none",
    [innertheme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  link: {
    margin: "0px 10px",
    color: theme.colors.White,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO },
  Polygon: {
    position: "absolute",
    right: "150px",
    opacity: "0.8",
    bottom: "-50px",
    display: "none",
    [innertheme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  Rectangle: {
    position: "absolute",
    bottom: "50px",
    left: "180px",
    opacity: "0.8",
    display: "none",
    [innertheme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  Ellipse: {
    position: "absolute",
    top: "20px",
    right: "500px",
    opacity: "0.8",
    zIndex: 1,
    display: "none",
    [innertheme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

function AppDownload() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Download App"}</title>
        <meta property="og:title" content={"Sanaam - Download App"} />
      </Helmet>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.logoContainer}>
              <img src="/images/logo_white.svg" alt="logo_white" />
            </div>
            <div className={classes.mainTitle}>
              Your financial
              <br /> Wellness starts <br />
              Today!
            </div>
          </div>
          <div className={classes.cardContainer}>
            <div className={classes.bingoImage}>
              <img src={"/images/success_bingo.svg"} alt="success_bingo" />
            </div>
            <div className={classes.titleText}>
              You have registered successfully!
              <br />
              <span style={{ fontFamily: theme.fontFamily.MARKPROHEAVY }}>
                Download Sanaam App
              </span>
            </div>
            <div className={classes.buttonContainer}>
              <img
                src={"/images/appStore.svg"}
                alt="appStore"
                style={{
                  width: "179px",
                  height: "48px",
                  margin: "5px",
                }}
              />
              <img
                src={"/images/playStore.svg"}
                alt="playStore"
                style={{
                  width: "179px",
                  height: "48px",
                  margin: "5px",
                }}
              />
            </div>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.innerBottomContainer}>
              <div className={classes.flexContainer}>
                <a className={classes.link} href={window.location.origin}>
                  About
                </a>
                <a className={classes.link} href={window.location.origin}>
                  Contact us
                </a>
              </div>
              <div className={classes.copyright}>
                Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/images/Ellipse.svg"
        alt="Ellipse"
        className={classes.Ellipse}
      />
      <img
        src="/images/Rectangle.svg"
        alt="Rectangle"
        className={classes.Rectangle}
      />
      <img
        src="/images/Polygon.svg"
        alt="Polygon 2"
        className={classes.Polygon}
      />
      <div className={classes.flexContainer}>
        <div className={classes.bottomContainer}>
          <div className={classes.flexContainer}>
            <a className={classes.link} href={window.location.origin}>
              About
            </a>
            <a className={classes.link} href={window.location.origin}>
              Contact us
            </a>
          </div>
          <div className={classes.copyright}>
            Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDownload;
