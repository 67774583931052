import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeList from "./employeeList";
import { getEmployeeListAction } from "../../containers/dashboard/action";

const dashedRegexp = RegExp("^-+$")

function SearchEmployee({ selectedEmployee, employeeState,updateEmployeeMethod }) {
  const dispatch = useDispatch();

  const getEmployeeListMethod = useCallback(
    (data) => dispatch(getEmployeeListAction(data)),
    [dispatch]
  );

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  useEffect(() => {
    if (loginReducer?.companyInformation?.id) {
      let filters = {
        page,
        page_size,
        company_id: loginReducer.companyInformation.id,
      };
      if (selectedEmployee?.label) {
        filters["page"] = 1;
        filters["search"] = selectedEmployee.label;
      }
      if (employeeState){
        if (employeeState.match(dashedRegexp)){
          filters["state"] = ""
        } else {
          filters["state"] = employeeState
        }
      }
      getEmployeeListMethod(filters);
    }
  }, [loginReducer, getEmployeeListMethod, page, page_size, selectedEmployee, employeeState]);

  useEffect(() => {
    if (dashboardReducer?.employeeList?.count) {
      setTotalPage(Math.ceil(dashboardReducer.employeeList.count / page_size));
    }
  }, [dashboardReducer]);

  return !dashboardReducer?.loading ? (
    <div style={{ marginTop: "30px" }}>
      <EmployeeList
        data={dashboardReducer?.employeeList?.data}
        companyInformation={loginReducer?.companyInformation}
        page={page}
        setPage={setPage}
        totalPage={totalPage}
        updateEmployeeMethod={updateEmployeeMethod}
      />
    </div>
  ) : null;
}

export default SearchEmployee;
