import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import theme from "../../theme";

import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((props) => ({
  root: {
    "& .datePicker": {
      padding: "8px 15px",
      border: (props) =>
        props.noBorder
          ? `1px solid ${theme.colors.Zumthor}`
          : `1px solid ${theme.colors.DodgerBlue}`,
      borderColor: (props) =>
        props.noBorder ? theme.colors.Zumthor : theme.colors.DodgerBlue,
      borderRadius: (props) =>
        props.borderRadius ? props.borderRadius : "20px",
      backgroundColor: theme.colors.Zumthor,
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      width: (props) => (props.width ? props.width : "145px"),
      "&:hover": {
        border: `1px solid ${theme.colors.DodgerBlue}`,
        borderColor: theme.colors.DodgerBlue,
      },
      "&:focus-visible": {
        border: `1px solid ${theme.colors.DodgerBlue}`,
        borderColor: theme.colors.DodgerBlue,
        outline: "none",
      },
    },
  },
}));

function EndDatePicker({
  endDate,
  setEndDate,
  minDate,
  maxDate,
  ...props
}) {
  const classes = useStyles(props);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  return (
    <div className={classes.root}>
      <input
        type="date"
        value={endDate}
        onChange={handleDateChange}
        className={`datePicker`}
        min={minDate || null}
        max={maxDate || null}
      />
    </div>
  );
}

export default EndDatePicker;
