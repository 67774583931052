import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import CommonFormDropDown from "../../components/formdropdown";
import ScrollDialog from "../../components/dialog/scrolldialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import theme from "../../theme";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles((innertheme) => ({
  formContainer: {
    boxShadow: "0px 0px 10px #CFD9EA80",
    padding: "20px 30px",
    borderRadius: "10px",
    margin: "20px 0px",
    width: "70%",
    [innertheme.breakpoints.up("sm")]: {
      width: "400px",
      padding: "40px 60px",
    },
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  subTitle: {
    marginTop: "40px",
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "20px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  checkbox: {
    color: theme.colors.DodgerBlue,
    textDecoration: "underline",
    margin: "0px 0px 25px 0px",
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPRO,
    "& .MuiCheckbox-root": { padding: "0px 10px 0px 0px" },
    "& .MuiIconButton-root:hover": { backgroundColor: "transparent" },
  },
}));

const GreenCheckbox = withStyles({
  root: {
    // color: theme.colors.White,
    "&$checked": {
      color: theme.colors.DodgerBlue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CompanyRegistrationStep3({
  setStep,
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  gender,
  setGender,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  isValidated,
  setIsValidated,
  errors,
  onBoardCompanyVerifyMethod,
  employeeWithdrawalLimit,
  setEmployeeWithdrawalLimit
}) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (errors && errors.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [errors]);

  return (
    <div className={classes.formContainer}>
      <ScrollDialog open={open} setOpen={setOpen} isChecked={isChecked} setIsChecked={setIsChecked} />
      <div className={classes.formTitle}>Add the company’s main contact</div>
      {errors && errors.length ? (
        <div className={"error-container"}>
          <div>You have the following errors, please fix them.</div>
          <br />
          {errors.map((each, index) => (
            <div key={index}>
              {each === "Resource not found."
                ? "Token does not exist or has expired."
                : each}
            </div>
          ))}
        </div>
      ) : null}
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsValidated(true);
          if (
            email &&
            firstName &&
            lastName &&
            gender &&
            phoneNumber &&
            phoneNumber.trim() &&
            password &&
            confirmPassword &&
            password === confirmPassword &&
            isChecked &&
            password.match(Constants.password_regex) &&
            ((employeeWithdrawalLimit <= 100) && employeeWithdrawalLimit >= 0)
          ) {
            setIsValidated(false);
            onBoardCompanyVerifyMethod();
          }
        }}
      >
        <CommonInput
          label="First Name"
          placeholder="Enter First Name"
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          error={isValidated && !firstName}
          helperText={
            isValidated && !firstName && "First Name may not be blank."
          }
        />
        <CommonInput
          label="Last Name"
          placeholder="Enter Last Name"
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          error={isValidated && !lastName}
          helperText={isValidated && !lastName && "Last Name may not be blank."}
        />
        <CommonInput
          label="Email"
          placeholder="Enter email"
          type="text"
          value={email}
          disabled
          onChange={(e) => {
            setEmail(e.target.value.trim());
          }}
          error={
            isValidated &&
            (!email || (email && !email.match(Constants.email_regex)))
          }
          helperText={
            isValidated &&
            ((!email && "Email may not be blank.") ||
              (email &&
                !email.match(Constants.email_regex) &&
                "Email is invalid."))
          }
        />
        <CommonInput
          label="Employee Withdrawal Limits (%)"
          placeholder="Enter Percentage"
          type="number"
          value={employeeWithdrawalLimit}
          onChange={(e) => { setEmployeeWithdrawalLimit(e.target.value) }}
          error={
            isValidated && typeof employeeWithdrawalLimit !== String
          }
          helperText={
            isValidated && (employeeWithdrawalLimit > 100 || employeeWithdrawalLimit < 0) && (
              "Max Should Not Be More Than 70 and Minimum Should Not be Less Than 0"
            )
          }
          min={5}
          max={100}
        />
        <CommonInput
          label="Phone Number"
          placeholder="Enter Phone Number"
          type="tel"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          error={isValidated && (!phoneNumber || !phoneNumber.trim())}
          helperText={
            isValidated &&
            (!phoneNumber || !phoneNumber.trim()) &&
            "Phone Number may not be blank."
          }
        />
        <CommonFormDropDown
          name="Gender"
          label="Gender"
          placeholder={"Select Gender"}
          options={[
            { value: "F", label: "Female" },
            { value: "M", label: "Male" },
          ]}
          value={gender}
          onChange={(val) => {
            setGender(val);
          }}
          error={isValidated && !gender}
          helperText={isValidated && !gender && "Gender may not be blank."}
        />
        <div className={classes.subTitle}>Set your Sanaam Password</div>
        <div style={{ position: "relative" }}>
          <CommonInput
            label="Set password"
            placeholder="Enter Set password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={
              isValidated &&
              (!password ||
                (password && !password.match(Constants.password_regex)))
            }
            helperText={
              isValidated &&
              ((!password && "Password may not be blank.") ||
                (password &&
                  !password.match(Constants.password_regex) &&
                  "Password is invalid."))
            }
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          />
          {hover ? (
            <div className={"tool_tip"}>
              - Password must be at least 1 character and max 48 characters.
              <br />- Must not contain any spaces in it.
            </div>
          ) : null}
        </div>
        <CommonInput
          label="Confirm Password"
          placeholder="Enter Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          error={
            (isValidated && !confirmPassword) ||
            (password && confirmPassword && password !== confirmPassword)
          }
          helperText={
            isValidated && !confirmPassword
              ? "Confirm Password may not be blank."
              : password && confirmPassword && password !== confirmPassword
                ? "Please enter the same password."
                : ""
          }
        />
        <div>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={isChecked}
                onChange={() => {
                  setOpen(!open)
                }}
                name="termsConditions"
              />
            }
            label="Terms and Conditions"
            className={classes.checkbox}
          />
        </div>
        <CommonButton
          type="submit"
          text="Next"
          stylevariant="primary"
          style={{ marginTop: "30px" }}
        />
        <CommonButton
          type="button"
          text="Back"
          stylevariant="secondary2"
          style={{ marginTop: "10px", border: "none" }}
          onClick={() => {
            setStep(2);
          }}
        />
      </form>
    </div>
  );
}

export default CompanyRegistrationStep3;
