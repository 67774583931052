const LOCAL = process.env.NODE_ENV === "development" ? 'http://localhost:8000' : null;
const STAGING = "https://staging-api.sanaam.tech";
const PROD = process.env.REACT_APP_API_URL || "https://api.sanaam.tech";
export const Constants = {
  BASE_URL: LOCAL || STAGING || PROD,
  ERROR: {
    INTERNAL_SERVER: "Something went wrong.",
    PERMISSION_ERROR: "You don't have a permission to use this platform.",
  },
  email_regex: new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+(\.[A-Z]{2,4})$/i),
  password_regex: new RegExp(/^(?!.* ).{1,48}$/gm),
};
