import React from 'react';

export const WithdrawalChargesTable = () => {
    return (
        <div style={{ padding: '20px' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                            FROM (₦)
                        </th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                            TO (₦)
                        </th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                            WITHDRAWAL CHARGES (₦)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>—</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>5000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>360</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>5001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>10000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>750</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>10001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>20000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>1550</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>20001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>50000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>3550</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>50001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>100000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>7050</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>100001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>200000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>14050</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>200001</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>300000</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>21050</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const TermsAndConditions = () => {
    return (
        <>
            <div style={{ padding: '20px', lineHeight: '1.6' }}>
                <h2>ABOUT THESE TERMS</h2>
                <p>
                    These terms and conditions (“Terms and Conditions”) constitute a legally binding agreement between you and Radical Technology Nigeria Limited, a limited liability company registered under the Laws of the Federal Republic of Nigeria, (the “Company”) and govern your use of the Sanaam Application (the “Application”). These Terms and Conditions shall govern your use of, and the terms and covenants upon which the Company will provide you with certain payroll, human resources and other related services through our Application (collectively, the “Services”).
                </p>
                <p>
                    In these Terms and Conditions, reference to “we”, “us”, “our” are references to the Company.
                </p>
                <p>
                    By using the Application and accepting the Terms and Conditions, you hereby approve same and warrant and represent that you have the right, authority and capacity to enter into these Terms and Conditions.
                </p>

                <h2>SERVICE</h2>
                <p>
                    The Application will provide you with the services, which include, but are not limited to: payroll management; payroll preparation, as well as, facilitating distribution of earned salary, financial management tools such as savings schemes, tracking of money flow on a daily basis. (the “Services”).
                </p>
                <p>
                    The manner in which the Services will be provided to you is described in detail in section 6 (“Earned Income Payments”).
                </p>

                <h2>ACCEPTANCE OF TERMS</h2>
                <p>
                    These Terms and Conditions govern your use of all websites, devices and mobile applications that we operate in association with the Application, including any pages within each such device, website, application or equivalent.
                </p>
                <p>
                    Please read these Terms and Conditions carefully. By accessing or using the Application, you agree to be bound by the Terms and Conditions. If you do not wish to be bound by these Terms and Conditions, you should not access nor use this Application.
                </p>
                <p>
                    The Company reserves the right to amend these Terms and Conditions from time to time, in full or in part, at its sole discretion, as and when it deems necessary, without prior notice. Any changes will be posted on the Sanaam website (www.sanaam.tech) as well as the Application. The Application will notify you upon the amendment of said Terms and Conditions. Your continued use of the Application (or any part thereof) following the implementation of said changes shall be deemed to be your acceptance of the new Terms and Conditions. If you do not approve of any of the amendments to the Terms and Conditions, you should immediately cease use of our Application.
                </p>
                <p>
                    Any additional agreements, policies, issuances, and amendments made in connection with these Terms constitute an integral part of these Terms.
                </p>

                <h2>APPLICABILITY OF APPSTORE TERMS AND CONDITIONS</h2>
                <p>
                    Your use of the Application is also controlled by the Apple Media Services Terms and Conditions/ Android Appstore Terms and Conditions
                    &nbsp;for your available jurisdiction, available here: <a href="https://www.apple.com/legal/internet-services/itunes/" target="_blank" rel="noopener noreferrer" title='Apple Media Services'>Apple Media Services</a>
                    &nbsp;and here: <a href="https://play.google.com/about/play-terms/index.html" target="_blank" rel="noopener noreferrer" title='Play Terms'>Android Appstore</a>.
                </p>

                <h2>APPLICATION, ACCOUNT AND SERVICES</h2>
                <p>
                    In this section of these Terms and Conditions, “Account” shall mean:
                </p>
                <ol>
                    <li>
                        The secure area of the Application which includes your Login ID and Password, together with any profile information, such as, but not limited to: your full name, physical address, mobile number, bank account details and other such information (collectively, “Account Information”);
                    </li>
                    <li>Your relationship with us.</li>
                    <li>
                        For the avoidance of doubt, your Account is not a bank account or an electronic money account or any other sort of payment account.
                    </li>
                    <li>
                        For the purpose of using the Application, you may only have one Account, which shall be utilized for personal and non-commercial use.
                    </li>
                    <li>
                        You are prohibited from creating and/or controlling more than one personal account for yourself without the prior express authorization of the Company, including, amongst other things, using a name that is not yours, using a temporary email address or phone number, or providing any other falsified personal information.
                    </li>
                    <li>
                        In any and all events, you are independently responsible for complying with all the Terms and Conditions herein, as well as all applicable laws and regulations in the Federal Republic of Nigeria, in all your actions related to your use of the Application.
                    </li>
                    <li>
                        You agree and understand that you are responsible for maintaining the confidentiality of your Account Information.
                    </li>
                    <li>
                        It is your sole responsibility to update your Account Information and bank account details whenever the information provided to us is no longer accurate. We are not responsible for any payment processing errors or Fees or other related issues arising from your failure to keep your Account Information updated.
                    </li>
                    <li>
                        If you believe that your Account Information has been compromised or a device that you use to access the Application has been lost or stolen, or if someone has gained access to your Account Information without your permission, you must notify us IMMEDIATELY at <a href="mailto:support@sanaam.tech" title='Send Mail To Sanaam Support'>support@sanaam.tech</a>.
                    </li>
                    <li>
                        Subject to payment of all applicable outstanding fees, you may close your Account and terminate your relationship with the Company without cost, for any reason. You will remain liable for all obligations related to your Account up until the Account closure date. At this point, we should remind you that we also have certain rights to suspend or terminate your Account, which are outlined in section 8 (Prohibited Use and Activities).
                    </li>
                    <li>
                        If you choose to cancel your Account, for any reason whatsoever, the cancellation will only become effective on your next salary cycle.
                    </li>
                    <li>
                        You have a responsibility to promptly relay to the Company any loss or theft of your device that would compromise the security of your data or proprietary information.
                    </li>
                </ol>

                <h2>EARNED INCOME PAYMENTS</h2>
                <p>
                    In this section:
                </p>
                <ol>
                    <li>
                        “Earned Income Payment” shall mean, following the acceptance of your request, the payment of a portion of the amount of earned but unpaid income set out in your request, earlier than the relevant Pay Day to your bank account or other means offered on the Application;
                    </li>
                    <li>
                        “Payment Cycle” means the time period in which the income is paid to you by your employer or government entity—in most cases this is done on a monthly basis;
                    </li>
                    <li>
                        “Request” means a request made via the Application to your employer to be paid a proportion of your accrued but unpaid income earlier than the relevant pay day to your bank account;
                    </li>
                    <li>
                        “Income” means the salary or commission payment made by your employer to you for work carried out during the Payment Cycle.
                    </li>
                    <li>
                        The Company is solely acting on behalf of your employer in providing any payments to your bank account. Ultimately, all decisions pertaining to the approval of each Earned Income Payment Request rest within the discretion of your employer. The Company, through its Application, is under no obligation to process your request and nor does it have any liability pertaining to any losses you may incur as a result of an unprocessed Request.
                    </li>
                    <li>
                        If you have received any Earned Income Payments prior to the Pay Day of your particular Payment Cycle, on the Pay Day for that Payment Cycle you will receive your “Net Income” which means your Income after the deductions of the total Earned Income Payments have been implemented.
                    </li>
                    <li>
                        Typically, Earned Income Payments will be executed within 24 hours of the Request, after such request(s) has been approved by your Employer, provided also, that the Request was made on the relevant section of the Application before the cut-off time for bank transactions for your respective banks. The Company shall not be liable for incurred delays in the execution of your Earned Income Payments due to the fault of your respective bank.
                    </li>
                    <li>
                        Furthermore, the Company shall not be liable for any incurred delays in processing of your Earned Income Payments, in the event that you are entitled to any commission or overtime incentives, which are subject to any external approvals.
                    </li>
                    <li>
                        By accepting these Terms and Conditions, you understand that any Earned Income Payments will be subject to external bank fees (“Bank Fees”), which may result in minor changes to the final payment received in your Account. The Company shall not be liable for any imposed Bank Fees in this respect.
                    </li>
                    <li>
                        Your Earned Income Payments shall be subject to a Fee, depending on the amount that is withdrawn, as highlighted in the table below:
                    </li>
                </ol>
                <WithdrawalChargesTable />
                <h2>WITHDRAWAL LIMITATIONS</h2>
                <ol>
                    <li>
                        In any event, you are limited to a maximum withdrawal of ₦300,000.00 per transaction.
                    </li>
                    <li>
                        It should be noted that the fees as stated may vary, subject to the prevailing economic situation.
                    </li>
                    <li>
                        The fees shall be stated on the Application prior to the transfer being made for your convenience. By agreeing to these Terms and Conditions, you agree to pay such accrued Fees on the relevant Pay Day.
                    </li>
                </ol>

                <h2>PROHIBITED USE AND ACTIVITIES</h2>
                <p>
                    In any and all events, you may not use the Application or Services for activities that:
                </p>
                <ul>
                    <li>Violate the provisions of these Terms and Conditions;</li>
                    <li>Violate any law, statute, ordinance or regulation;</li>
                    <li>License, sell, rent, lease, transfer, assign, distribute, host or otherwise exploit the Application, whether in whole or in part, or any content displayed therein;</li>
                    <li>Infringe our or any third party’s copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;</li>
                    <li>Cause us to lose any of the services from our internet service providers, payment processors, or other suppliers or service providers;</li>
                    <li>Impose an unreasonable or disproportionately large load on our Application (including any networks and servers used to provide any of our Services);</li>
                    <li>Upload, transmit, or distribute to or through the Platform any computer viruses, worms, or any software intended to damage or alter a computer system or data;</li>
                    <li>Reverse engineer or disassemble any of the software comprising all or part of the Application;</li>
                    <li>Attempt to build a similar or competitive website, product or Service;</li>
                    <li>Reveal your account password to others or allow use of your account by others.</li>
                </ul>
                <p>
                    In the event that the Company believes, in its sole discretion, that you may have engaged in any of the abovementioned prohibited activities, we may take various actions to protect ourselves from any liability, including:
                </p>
                <ul>
                    <li>Terminating, suspending, or limiting your access to your Account to use the Application and the Services;</li>
                    <li>Refusing to allow you to use the Application in the future;</li>
                    <li>Reporting you to law enforcement authorities and taking any and all necessary legal action against you;</li>
                    <li>Holding you liable to pay for the amount of the incurred damages caused by your violation(s).</li>
                </ul>

                <h2>WARRANTIES</h2>
                <p>
                    The Company has taken all due care in the preparation of the Application and the Services. However, we do not represent or warrant, whether expressly or impliedly, the accuracy, reliability or operation of the Application or any content therein.
                </p>
                <p>
                    This Application is provided on an “As Is” basis without any warranty in respect of the functionalities.
                </p>
                <p>
                    The Company also makes no representations or warranties, whether express or implied, that the content available on the Application or any of its related Services will be available on an uninterrupted basis, and we accept no liability in respect of damage or loss arising out of such unavailability.
                </p>
                <p>
                    The Application is not intended to provide you with financial advice. The Company is not a financial planner, adviser, or broker. To this extent, you should always seek the approval or advice of your financial adviser or any other professional fully aware of your individual circumstances, prior to making any financial decisions or implementing any financial strategy.
                </p>

                <h2>LIMITATION OF LIABILITY</h2>
                <p>
                    The Company shall not be liable to you for any of the following whether arising from any claim in connection with the use of our Application or Services, including without limitation, under any tort, including negligence, for breach of contract, for misrepresentation, property infringement or under any statute or otherwise:
                </p>
                <ul>
                    <li>Your usage of the Application for any commercial, business or re-sale purpose;</li>
                    <li>Any indirect, special or consequential losses, including, without limitation, any loss of business, data, profits, revenue, goodwill, use or anticipated savings;</li>
                    <li>Loss or damage to your, or any third party’s, data or records;</li>
                    <li>Any delay in, or failure of, performance of our obligations under these Terms and Conditions as a result of an unforeseeable force majeure event.</li>
                </ul>

                <h2>LINKS AND THIRD-PARTY CONTENT</h2>
                <p>
                    Certain links in our Application may direct you to third party webpages and services and/or display content or advertisements for third parties (collectively, “Third Party Content”). Where such Third Party Content is available, it is for your convenience only. Links provided on or through our Application do not represent any endorsement or other acceptance of responsibility for any content provided or made available on such Third Party Content. When any of these links are activated, you will leave our Application and we have no control over and will not accept responsibility, or liability, for any material or service contained on any linked webpage.
                </p>

                <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
                <p>
                    The Company is the owner and/or licensee (as applicable) of all copyright, design rights, database rights, trademarks and other intellectual property rights on or in connection with the Application, Services, and Accounts (“Intellectual Property”). Your use of the Application, Services, or Account grants no rights to you in relation to the intellectual property rights of the Company or the intellectual property rights of third parties. You may not modify, copy, translate, broadcast, perform, distribute, frame, reproduce, republish, post, transmit or sell any content or intellectual property appearing on the Application, Services, or Account.
                </p>

                <h2>PRIVACY POLICY</h2>
                <p>
                    Your privacy is very important to us. This policy sets out what type of information we collect, and how we use and protect such information and any information that you may give us when you use our Application.
                </p>
                <p>
                    The Application is always improving. As they evolve, we may occasionally update this privacy policy. If we modify this privacy policy, we will post the revised privacy policy on our Application, and the “last updated date” will be amended accordingly. If we make material changes in the way we use personal information, we will notify you by posting an announcement on our Application and/or by sending you an e-mail. It is your responsibility to periodically review this privacy policy; users are bound by any changes to the privacy policy by using the Application after such changes have been first posted.
                </p>
                <p>
                    You are responsible for exercising good judgment regarding the reasonableness of personal use.
                </p>
                <p>
                    This privacy policy applies to all information we collect through our Application from current and former users, including you. When you are no longer our customer, we continue to use and/or share your information as described in this policy.
                </p>
                <p>
                    When you create an Account, we will ask you to provide us with, and we will collect, information such as your name, email address, telephone number, date of birth, and password(s). We also collect, (and our third-party providers of advertisements and content may collect) certain information about user activity on our Application. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                </p>
                <p>
                    When you are using our Application, we collect information about your Account transactions and we may collect Geolocation Information and/or information about your computer, mobile, tablet, or other access device, for fraud prevention and other similar purposes. If you wish to change our access or permissions, you may do so in your device’s settings.
                </p>
                <p>
                    We use your personal information collected via the Application for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, for the purposes dictated below:
                </p>
                <ul>
                    <li>To process your Earned Income Payment;</li>
                    <li>For statistical analysis purposes;</li>
                    <li>To improve our Application and Services;</li>
                    <li>To provide you with necessary customer service and support;</li>
                    <li>To provide you with content and advertisements which we think you might be interested in;</li>
                    <li>To personalize our communications with you and your experience of our Application and Services;</li>
                    <li>To administer the Application and Services;</li>
                    <li>To contact you in relation to your Account Registration and to send you any credentials required for you to access and use your Account on the Application;</li>
                    <li>To prevent potentially fraudulent, prohibited or illegal activities, and enforce our Terms through the use of our risk and fraud tools which may include use of your Account Information, identification information, financial information, device information, social web information and geolocation information;</li>
                    <li>For our strictly confidential internal records.</li>
                </ul>
                <p>
                    For protection purposes, we maintain procedural, technical and physical protection safeguards for the Application, Services, and Accounts, to help protect against loss, misuse or unauthorized access, disclosure, alteration or destruction of the information you provide via the Application, Services, and Accounts. We strive to ensure security on our systems. Despite our efforts, we cannot guarantee that personal information may not be accessed, disclosed, altered or destroyed by breach of our administrative, managerial and technical safeguards. Therefore, we urge you to take adequate precautions to protect your personal data as well, including never sharing your Account credentials or information with anyone.
                </p>
                <p>
                    To process Earned Income Payments via our Application, we need to share some of your personal information with your employer in order to validate the information provided by you. We will share your identity data, contact data, financial data and transaction data with your employer.
                </p>
                <p>
                    We are constantly looking for ways to improve our Application and Services and may therefore occasionally contact you for research purposes via SMS and/or email. You hereby consent that we may contact you as described above through appropriate means or whenever we deem necessary or are required to do so by law.
                </p>
                <p>
                    We may retain your data and any of the information mentioned above for as long as you use the Application and/or Services and for a reasonable time thereafter.
                </p>
                <p>
                    When you access or use our Application or Services, or visit a third-party website for which we provide online services, we and certain business partners and vendors may use cookies and other tracking technologies (collectively, “Cookies”). We use Cookies to recognize you as a customer; customize services, other content and advertising; measure the effectiveness of promotions; perform a wide range of analytics; mitigate risk and prevent potential fraud; and to promote trust and safety across our Services.
                </p>
                <p>
                    Certain Services are only available through the use of Cookies. Should you choose to disable or decline Cookies, your use of certain Services may be limited or not possible.
                </p>

                <h2>GOVERNING LAW AND JURISDICTION</h2>
                <p>
                    These Terms shall be governed by and interpreted pursuant to the Laws of the Federal Republic of Nigeria, and the competent Courts shall have jurisdiction over any dispute arising out of or in connection with these terms.
                </p>
            </div>
        </>
    );
};

export default TermsAndConditions;