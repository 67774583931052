import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CommonInput from "../../components/input";
import CommonButton from "../../components/button";
import theme from "../../theme";
import ScrollDialog from "../../components/dialog/scrolldialog";
import {
  onBoardEmployeeInfoAction,
  onBoardEmployeeVerifyAction,
} from "./action";
import Loading from "../../components/loading";
import history from "../../history";
import { Constants } from "../../lib/constant";

const useStyles = makeStyles((innertheme) => ({
  root: {
    backgroundColor: theme.colors.DodgerBlue,
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
  },
  container: {
    padding: "20px",
    display: "block",
    [innertheme.breakpoints.up("sm")]: {
      padding: "100px",
      display: "flex",
    },
  },
  left: {
    width: "auto",
    marginRight: "0px",
    [innertheme.breakpoints.up("sm")]: { width: "370px", marginRight: "50px" },
  },
  logoContainer: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [innertheme.breakpoints.up("sm")]: { display: "block" },
    "& img": {
      height: "76px",
      width: "100px",
    },
  },
  mainTitle: {
    color: theme.colors.White,
    fontSize: "60px",
    fontFamily: theme.fontFamily.MARKPROBLACK,
    marginBottom: "20px",
    textAlign: "center",
    [innertheme.breakpoints.up("sm")]: { textAlign: "left" },
  },
  bulletsContainer: {},
  bullet: { display: "flex", marginBottom: "20px" },
  bulletIcon: { height: "70px", width: "70px", marginRight: "20px" },
  bulletText: {
    color: theme.colors.White,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginTop: "10px",
  },
  formContainer: {
    backgroundColor: theme.colors.White,
    borderRadius: "15px",
    padding: "30px 50px",
  },
  subTitle: {
    marginBottom: "30px",
    color: theme.colors.Violet,
    fontSize: "21px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    textTransform: "capitalize",
  },
  formTitle: {
    marginBottom: "20px",
    color: theme.colors.DodgerBlue,
    fontSize: "21px",
    fontWeight: "bold",
    fontFamily: theme.fontFamily.MARKPROBOLD,
  },
  note: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    margin: "50px 0px 25px 0px",
  },
  checkbox: {
    color: theme.colors.DodgerBlue,
    textDecoration: "underline",
    margin: "0px 0px 25px 0px",
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPRO,
    "& .MuiCheckbox-root": { padding: "0px 10px 0px 0px" },
    "& .MuiIconButton-root:hover": { backgroundColor: "transparent" },
  },
  buttonContainer: {
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomContainer: {
    color: theme.colors.White,
    fontSize: "16px",
    marginBottom: "100px",
    padding: "10px",
  },
  link: {
    margin: "0px 10px",
    color: theme.colors.White,
    marginBottom: "10px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  copyright: { fontFamily: theme.fontFamily.MARKPRO },
}));

const GreenCheckbox = withStyles({
  root: {
    // color: theme.colors.White,
    "&$checked": {
      color: theme.colors.DodgerBlue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function EmployeeConfirmRegistration({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onBoardEmployeeInfoMethod = useCallback(
    (data) => dispatch(onBoardEmployeeInfoAction(data)),
    [dispatch]
  );

  const onBoardEmployeeVerifyMethod = useCallback(
    (data) => dispatch(onBoardEmployeeVerifyAction(data)),
    [dispatch]
  );

  const employeeRegistrationReducer = useSelector((state) => {
    return state.employeeRegistrationReducer;
  });

  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [IBAN, setIBAN] = useState("");
  const [nin, setNin] = useState("");
  const [bvn, setBvn] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [monthlyPay, setMonthlyPay] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (match?.params?.cid && match?.params?.eid) {
      onBoardEmployeeInfoMethod({
        cid: match.params.cid,
        eid: match.params.eid,
      });
    }
  }, [match, onBoardEmployeeInfoMethod]);

  useEffect(() => {
    if (employeeRegistrationReducer?.onBoardEmploeeinfo) {
      setFirstName(employeeRegistrationReducer?.onBoardEmploeeinfo?.first_name);
      setLastName(employeeRegistrationReducer?.onBoardEmploeeinfo?.last_name);
      setEmail(employeeRegistrationReducer?.onBoardEmploeeinfo?.email);
      setNin(employeeRegistrationReducer?.onBoardEmploeeinfo?.profile?.nin);
      setBvn(employeeRegistrationReducer?.onBoardEmploeeinfo?.profile?.bvn);
      setPhoneNumber(
        employeeRegistrationReducer?.onBoardEmploeeinfo?.profile?.phone
      );
      if (employeeRegistrationReducer?.onBoardEmploeeinfo?.banks?.length) {
        setAccountHolderName(
          employeeRegistrationReducer.onBoardEmploeeinfo.banks[0].account_holder
        );
        setBankName(
          employeeRegistrationReducer.onBoardEmploeeinfo.banks[0].name
        );
        setAccountNumber(
          employeeRegistrationReducer.onBoardEmploeeinfo.banks[0].number
        );
        setIBAN(employeeRegistrationReducer.onBoardEmploeeinfo.banks[0].iban);
      }
      setJobTitle(
        employeeRegistrationReducer?.onBoardEmploeeinfo?.profile?.position
      );
      setMonthlyPay(
        employeeRegistrationReducer?.onBoardEmploeeinfo?.profile?.salary
      );
    }
    if (employeeRegistrationReducer?.success) {
      history.push("/app/download");
    }
  }, [employeeRegistrationReducer]);

  useEffect(() => {
    if (employeeRegistrationReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [employeeRegistrationReducer]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Employee Confirm Registration"}</title>
        <meta
          property="og:title"
          content={"Sanaam - Employee Confirm Registration"}
        />
      </Helmet>
      <Loading showLoader={employeeRegistrationReducer?.loading} />
      <ScrollDialog isChecked={isChecked} setIsChecked={setIsChecked} open={open} setOpen={setOpen}/>
      <div>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.logoContainer}>
              <img src="/images/logo_white.svg" alt="logo_white" />
            </div>
            <div className={classes.mainTitle}>
              Your pay…
              <br /> Every day!
            </div>
            <div className={classes.bulletsContainer}>
              <div className={classes.bullet}>
                <img
                  className={classes.bulletIcon}
                  src="/images/DoneIcon.svg"
                  alt="DoneIcon"
                />
                <span className={classes.bulletText}>
                  <b> Get real-time control</b> and insight over your earned
                  salary.
                </span>
              </div>
              <div className={classes.bullet}>
                <img
                  className={classes.bulletIcon}
                  src="/images/DoneIcon.svg"
                  alt="DoneIcon"
                />
                <span className={classes.bulletText}>
                  <b>Withdraw</b> any amount of your earned pay on daily basis.
                </span>
              </div>
              <div className={classes.bullet}>
                <div>
                  <img
                    className={classes.bulletIcon}
                    src="/images/DoneIcon.svg"
                    alt="DoneIcon"
                  />
                </div>
                <div className={classes.bulletText}>
                  <b>Set a monthly savings goal</b> and get access to your
                  available withdrawal balance without worrying about
                  over-spending.
                </div>
              </div>
            </div>
          </div>
          <div className={classes.formContainer}>
            {step === 1 ? (
              <form autoComplete="off">
                <div className={classes.subTitle}>STEP ONE</div>
                {employeeRegistrationReducer?.error?.length ? (
                  <div className={"error-container"}>
                    {employeeRegistrationReducer.error.map((each, index) => (
                      <div key={index}>
                        {each === "Resource not found."
                          ? "Token does not exist or has expired."
                          : each}
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className={classes.formTitle}>Personal Information</div>
                <CommonInput
                  label="First Name"
                  placeholder="Enter First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="Email"
                  placeholder="Enter Email"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                  }}
                  disabled
                />
                <CommonInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  error={!phoneNumber || !phoneNumber.trim()}
                  helperText={
                    !phoneNumber || !phoneNumber.trim()
                      ? "Phone Number may not be blank."
                      : ""
                  }
                />
                <div className={classes.formTitle}>
                  Bank Account Information
                </div>
                <CommonInput
                  label="Account Holder Name"
                  placeholder="Enter Account Holder Name"
                  type="text"
                  value={accountHolderName}
                  onChange={(e) => {
                    setAccountHolderName(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="Bank Name"
                  placeholder="Enter Bank Name"
                  type="text"
                  value={bankName}
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="Account Number"
                  placeholder="Enter Account Number"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="IBAN"
                  placeholder="Enter IBAN"
                  type="text"
                  value={IBAN}
                  onChange={(e) => {
                    setIBAN(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="NIN"
                  placeholder="Enter NIN"
                  type="text"
                  value={nin}
                  onChange={(e) => {
                    setNin(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="BVN"
                  placeholder="Enter Bvn"
                  type="text"
                  value={bvn}
                  onChange={(e) => {
                    setBvn(e.target.value);
                  }}
                  disabled
                />
                <div className={classes.formTitle}>Job Information</div>
                <CommonInput
                  label="Job Title"
                  placeholder="Enter Job Title"
                  type="text"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                  disabled
                />
                <CommonInput
                  label="Monthly Pay"
                  placeholder="Enter Monthly Pay"
                  type="number"
                  value={monthlyPay}
                  allowDecimal={3}
                  onChange={(e) => {
                    setMonthlyPay(e.target.value);
                  }}
                  disabled
                />

                <div className={classes.note}>
                  Please contact your admin HR if any of the
                  <br /> information above is incorrect or incomplete
                </div>

                <div className={classes.buttonContainer}>
                  <CommonButton
                    type="button"
                    text="Next"
                    stylevariant="secondary1"
                    style={{ marginTop: "10px", width: "80%" }}
                    onClick={() => {
                      setStep(2);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              </form>
            ) : step === 2 ? (
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  onBoardEmployeeVerifyMethod({
                    cid: match.params.cid,
                    eid: match.params.eid,
                    data: {
                      email: email,
                      password: password,
                      first_name: firstName,
                      last_name: lastName,
                      gender:
                        employeeRegistrationReducer.onBoardEmploeeinfo.gender,
                      banks: [
                        {
                          id: employeeRegistrationReducer?.onBoardEmploeeinfo
                            ?.banks?.length
                            ? employeeRegistrationReducer.onBoardEmploeeinfo
                                .banks[0].id
                            : "",
                          name: bankName,
                          number: accountNumber,
                          iban: IBAN,
                          account_holder: accountHolderName,
                        },
                      ],
                      profile: {
                        id: employeeRegistrationReducer?.onBoardEmploeeinfo
                          ?.profile?.id,
                        position: jobTitle,
                        phone: phoneNumber,
                        salary: monthlyPay,
                        bvn: bvn,
                        nin: nin
                      },
                    },
                  });
                }}
              >
                <div className={classes.subTitle}>STEP TWO</div>
                {employeeRegistrationReducer?.error?.length ? (
                  <div className={"error-container"}>
                    {employeeRegistrationReducer.error.map((each, index) => (
                      <div key={index}>
                        {each === "Resource not found."
                          ? "Token does not exist or has expired."
                          : each}
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className={classes.formTitle}>
                  Set your Sanaam Password
                </div>
                <div style={{ position: "relative" }}>
                  <CommonInput
                    label="Set password"
                    placeholder="Enter Set password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    // error={
                    //   !password ||
                    //   (password && !password.match(Constants.password_regex))
                    // }
                    // helperText={
                    //   (!password && "Password may not be blank.") ||
                    //   (password &&
                    //     !password.match(Constants.password_regex) &&
                    //     "Password is invalid.")
                    // }
                    onMouseEnter={() => {
                      setHover(true);
                    }}
                    onMouseLeave={() => {
                      setHover(false);
                    }}
                  />
                  {hover ? (
                    <div className={"tool_tip"}>
                      - Password must be at least 1 character and max 48
                      characters.
                      <br />- Must not contain any spaces in it.
                    </div>
                  ) : null}
                </div>
                <CommonInput
                  label="Confirm Password"
                  placeholder="Enter Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  error={
                    password && confirmPassword && password !== confirmPassword
                  }
                  helperText={
                    password && confirmPassword && password !== confirmPassword
                      ? "Please enter the same password."
                      : ""
                  }
                />
                <div>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={isChecked}
                        onChange={() => {
                          setOpen((temp) => !temp)
                        }}
                        name="termsConditions"
                      />
                    }
                    label="Terms and Conditions"
                    placeholder="Enter Terms and Conditions"
                    className={classes.checkbox}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <CommonButton
                    type="submit"
                    disabled={
                      !isChecked ||
                      !password ||
                      !confirmPassword ||
                      password !== confirmPassword ||
                      !password.match(Constants.password_regex)
                    }
                    text="Confirm Registration"
                    stylevariant="secondary1"
                    style={{
                      marginTop: "10px",
                      width: "80%",
                      marginBottom: 10,
                    }}
                  />
                  <CommonButton
                    type="button"
                    text="Back"
                    stylevariant="secondary2"
                    style={{ marginTop: "10px", width: "80%" }}
                    onClick={() => {
                      setStep(1);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              </form>
            ) : null}
          </div>
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.bottomContainer}>
            <div className={classes.flexContainer}>
              <a className={classes.link} href={window.location.origin}>
                About
              </a>
              <a className={classes.link} href={window.location.origin}>
                Contact us
              </a>
            </div>
            <div className={classes.copyright}>
              Copyright © {(new Date()).getFullYear()} Sanaam. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeConfirmRegistration;
