export const DASHBOARD_LOADING_ACTION = "DASHBOARD_LOADING";
export const PAYMENT_LIST_ACTION = "PAYMENT_LIST_ACTION";
export const PAYMENT_LIST_LOADING = "PAYMENT_LIST_LOADING";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_ERROR = "PAYMENT_LIST_ERROR";

export const PAYMENT_DOWNLOAD_ACTION = "PAYMENT_DOWNLOAD_ACTION";
export const PAYMENT_DOWNLOAD_LOADING = "PAYMENT_DOWNLOAD_LOADING";
export const PAYMENT_DOWNLOAD_SUCCESS = "PAYMENT_DOWNLOAD_SUCCESS";
export const PAYMENT_DOWNLOAD_ERROR = "PAYMENT_DOWNLOAD_ERROR";
export const PAYMENT_DOWNLOAD_RESET_ACTION = "PAYMENT_DOWNLOAD_RESET_ACTION";

export const GET_PAYMENT_ACTION = "GET_PAYMENT_ACTION";
export const GET_PAYMENT_LOADING = "GET_PAYMENT_LOADING";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_ERROR = "GET_PAYMENT_ERROR";

export const SETTLE_PAYMENT_ACTION = "SETTLE_PAYMENT_ACTION";
export const SETTLE_PAYMENT_LOADING = "SETTLE_PAYMENT_LOADING";
export const SETTLE_PAYMENT_SUCCESS = "SETTLE_PAYMENT_SUCCESS";
export const SETTLE_PAYMENT_ERROR = "SETTLE_PAYMENT_ERROR";

export const SETTLE_SALARY_ACTION = "SETTLE_SALARY_ACTION";
export const SETTLE_SALARY_LOADING = "SETTLE_SALARY_LOADING";
export const SETTLE_SALARY_SUCCESS = "SETTLE_SALARY_SUCCESS";
export const SETTLE_SALARY_ERROR = "SETTLE_SALARY_ERROR";

export const BULK_SETTLE_SALARY_ACTION = "BULK_SETTLE_SALARY_ACTION";
export const BULK_SETTLE_SALARY_LOADING = "BULK_SETTLE_SALARY_LOADING";
export const BULK_SETTLE_SALARY_SUCCESS = "BULK_SETTLE_SALARY_SUCCESS";
export const BULK_SETTLE_SALARY_ERROR = "BULK_SETTLE_SALARY_ERROR";

export const MONTHS_TO_SETTLE_ACTION = "MONTHS_TO_SETTLE_ACTION";
export const MONTHS_TO_SETTLE_LOADING = "MONTHS_TO_SETTLE_LOADING";
export const MONTHS_TO_SETTLE_SUCCESS = "MONTHS_TO_SETTLE_SUCCESS";
export const MONTHS_TO_SETTLE_ERROR = "MONTHS_TO_SETTLE_ERROR";

export const BULK_MONTHS_TO_SETTLE_ACTION = "BULK_MONTHS_TO_SETTLE_ACTION";
export const BULK_MONTHS_TO_SETTLE_LOADING = "BULK_MONTHS_TO_SETTLE_LOADING";
export const BULK_MONTHS_TO_SETTLE_SUCCESS = "BULK_MONTHS_TO_SETTLE_SUCCESS";
export const BULK_MONTHS_TO_SETTLE_ERROR = "BULK_MONTHS_TO_SETTLE_ERROR";

export const COMPANY_LIST_ACTION = "COMPANY_LIST_ACTION";
export const COMPANY_LIST_LOADING = "COMPANY_LIST_LOADING";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_ERROR = "COMPANY_LIST_ERROR";

export const GET_COMPANY_ACTION = "GET_COMPANY_ACTION";
export const GET_COMPANY_LOADING = "GET_COMPANY_LOADING";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export const UPDATE_COMPANY_ACTION = "UPDATE_COMPANY_ACTION";
export const UPDATE_COMPANY_LOADING = "UPDATE_COMPANY_LOADING";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const EMPLOYEE_LIST_ACTION = "EMPLOYEE_LIST_ACTION";
export const EMPLOYEE_LIST_LOADING = "EMPLOYEE_LIST_LOADING";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_ERROR = "EMPLOYEE_LIST_ERROR";

export const GET_EMPLOYEE_ACTION = "GET_EMPLOYEE_ACTION";
export const GET_EMPLOYEE_LOADING = "GET_EMPLOYEE_LOADING";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";

export const OVERVIEW_ACTION = "OVERVIEW_ACTION";
export const OVERVIEW_LOADING = "OVERVIEW_LOADING";
export const OVERVIEW_SUCCESS = "OVERVIEW_SUCCESS";
export const OVERVIEW_ERROR = "OVERVIEW_ERROR";

export const INVITE_COMPANY_ACTION = "INVITE_COMPANY_ACTION";
export const INVITE_COMPANY_LOADING = "INVITE_COMPANY_LOADING";
export const INVITE_COMPANY_SUCCESS = "INVITE_COMPANY_SUCCESS";
export const INVITE_COMPANY_ERROR = "INVITE_COMPANY_ERROR";

export const EXCHANGE_LIST_ACTION = "EXCHANGE_LIST_ACTION";
export const EXCHANGE_LIST_LOADING = "EXCHANGE_LIST_LOADING";
export const EXCHANGE_LIST_SUCCESS = "EXCHANGE_LIST_SUCCESS";
export const EXCHANGE_LIST_ERROR = "EXCHANGE_LIST_ERROR";

export const GET_EXCHANGE_ACTION = "GET_EXCHANGE_ACTION";
export const GET_EXCHANGE_LOADING = "GET_EXCHANGE_LOADING";
export const GET_EXCHANGE_SUCCESS = "GET_EXCHANGE_SUCCESS";
export const GET_EXCHANGE_ERROR = "GET_EXCHANGE_ERROR";

export const CREATE_EXCHANGE_ACTION = "CREATE_EXCHANGE_ACTION";
export const CREATE_EXCHANGE_LOADING = "CREATE_EXCHANGE_LOADING";
export const CREATE_EXCHANGE_SUCCESS = "CREATE_EXCHANGE_SUCCESS";
export const CREATE_EXCHANGE_ERROR = "CREATE_EXCHANGE_ERROR";

export const UPDATE_EXCHANGE_ACTION = "UPDATE_EXCHANGE_ACTION";
export const UPDATE_EXCHANGE_LOADING = "UPDATE_EXCHANGE_LOADING";
export const UPDATE_EXCHANGE_SUCCESS = "UPDATE_EXCHANGE_SUCCESS";
export const UPDATE_EXCHANGE_ERROR = "UPDATE_EXCHANGE_ERROR";

export const DELETE_EXCHANGE_ACTION = "DELETE_EXCHANGE_ACTION";
export const DELETE_EXCHANGE_LOADING = "DELETE_EXCHANGE_LOADING";
export const DELETE_EXCHANGE_SUCCESS = "DELETE_EXCHANGE_SUCCESS";
export const DELETE_EXCHANGE_ERROR = "DELETE_EXCHANGE_ERROR";


export const BACKOFFICE_DEDUCTION_LIST_ACTION =
  "BACKOFFICE_DEDUCTION_LIST_ACTION";
export const BACKOFFICE_DEDUCTION_LIST_LOADING =
  "BACKOFFICE_DEDUCTION_LIST_LOADING";
export const BACKOFFICE_DEDUCTION_LIST_SUCCESS =
  "BACKOFFICE_DEDUCTION_LIST_SUCCESS";
export const BACKOFFICE_DEDUCTION_LIST_ERROR =
  "BACKOFFICE_DEDUCTION_LIST_ERROR";

export const GET_DEDUCTION_ACTION = "GET_DEDUCTION_ACTION";
export const GET_DEDUCTION_LOADING = "GET_DEDUCTION_LOADING";
export const GET_DEDUCTION_SUCCESS = "GET_DEDUCTION_SUCCESS";
export const GET_DEDUCTION_ERROR = "GET_DEDUCTION_ERROR";

export const CREATE_DEDUCTION_ACTION = "CREATE_DEDUCTION_ACTION";
export const CREATE_DEDUCTION_LOADING = "CREATE_DEDUCTION_LOADING";
export const CREATE_DEDUCTION_SUCCESS = "CREATE_DEDUCTION_SUCCESS";
export const CREATE_DEDUCTION_ERROR = "CREATE_DEDUCTION_ERROR";

export const UPDATE_DEDUCTION_ACTION = "UPDATE_DEDUCTION_ACTION";
export const UPDATE_DEDUCTION_LOADING = "UPDATE_DEDUCTION_LOADING";
export const UPDATE_DEDUCTION_SUCCESS = "UPDATE_DEDUCTION_SUCCESS";
export const UPDATE_DEDUCTION_ERROR = "UPDATE_DEDUCTION_ERROR";

export const DELETE_DEDUCTION_ACTION = "DELETE_DEDUCTION_ACTION";
export const DELETE_DEDUCTION_LOADING = "DELETE_DEDUCTION_LOADING";
export const DELETE_DEDUCTION_SUCCESS = "DELETE_DEDUCTION_SUCCESS";
export const DELETE_DEDUCTION_ERROR = "DELETE_DEDUCTION_ERROR";

export const OVERVIEW_FILTER_ACTION = "OVERVIEW_FILTER_ACTION";
export const OVERVIEW_FILTER_SUCCESS = "OVERVIEW_FILTER_SUCCESS";
export const TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION =
  "TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_ACTION";
export const TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS =
  "TRANSACTIONS_WITHDRAWAL_REQUESTS_FILTER_SUCCESS";
export const TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION =
  "TRANSACTIONS_OVERTIME_REQUESTS_FILTER_ACTION";
export const TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS =
  "TRANSACTIONS_OVERTIME_REQUESTS_FILTER_SUCCESS";

export const TRANSACTION_HISTORY_ACTION = "TRANSACTION_HISTORY_ACTION";
export const TRANSACTION_HISTORY_LOADING = "TRANSACTION_HISTORY_LOADING";
export const TRANSACTION_HISTORY_SUCCESS = "TRANSACTION_HISTORY_SUCCESS";
export const TRANSACTION_HISTORY_ERROR = "TRANSACTION_HISTORY_ERROR";

export const BACKOFFICE_COUNTRY_FEES_LIST_ACTION =
  "BACKOFFICE_COUNTRY_FEES_LIST_ACTION";
export const BACKOFFICE_COUNTRY_FEES_LIST_LOADING =
  "BACKOFFICE_COUNTRY_FEES_LIST_LOADING";
export const BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS =
  "BACKOFFICE_COUNTRY_FEES_LIST_SUCCESS";
export const BACKOFFICE_COUNTRY_FEES_LIST_ERROR =
  "BACKOFFICE_COUNTRY_FEES_LIST_ERROR";
export const CREATE_COUNTRY_FEES_ACTION = "CREATE_COUNTRY_FEES_ACTION";
export const CREATE_COUNTRY_FEES_LOADING = "CREATE_COUNTRY_FEES_LOADING";
export const CREATE_COUNTRY_FEES_SUCCESS = "CREATE_COUNTRY_FEES_SUCCESS";
export const CREATE_COUNTRY_FEES_ERROR = "CREATE_COUNTRY_FEES_ERROR";

export const UPDATE_COUNTRY_FEES_ACTION = "UPDATE_COUNTRY_FEES_ACTION";
export const UPDATE_COUNTRY_FEES_LOADING = "UPDATE_COUNTRY_FEES_LOADING";
export const UPDATE_COUNTRY_FEES_SUCCESS = "UPDATE_COUNTRY_FEES_SUCCESS";
export const UPDATE_COUNTRY_FEES_ERROR = "UPDATE_COUNTRY_FEES_ERROR";

export const PAYMENT_SUB_TABLE_LIST_SUCCESS = "PAYMENT_SUB_TABLE_LIST_SUCCESS";
export const PAYMENT_SUB_TABLE_LIST_ERROR = "PAYMENT_SUB_TABLE__LIST_ERROR";
export const PAYMENT_SUB_TABLE_LIST_LOADING = "PAYMENT_SUB_TABLE_LIST_LOADING";
export const PAYMENT_SUB_TABLE_LIST_ACTION = "PAYMENT_SUB_TABLE_LIST_ACTION";


export const DECLINE_PAYMENT_ACTION = "DECLINE_PAYMENT_ACTION";
export const DECLINE_PAYMENT_LOADING = "DECLINE_PAYMENT_LOADING";
export const DECLINE_PAYMENT_SUCCESS = "DECLINE_PAYMENT_SUCCESS";
export const DECLINE_PAYMENT_ERROR = "DECLINE_PAYMENT_ERROR";