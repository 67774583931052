import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import CommonSwitch from "../switch";
import theme from "../../theme";
import TransactionHistory from "./transactionHistory";
import { getEmployeeInfoAction } from "../../containers/dashboard/action";
import ConfirmSwitchModal from "../confirmSwitchModal";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  detailContainer: {
    marginTop: "30px",
    "& .block": {
      cursor: "pointer",
      background: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "15px",
      padding: "30px",
      "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .employeeName": {
          fontFamily: theme.fontFamily.MARKPROBOLD,
          fontSize: "22px",
        },
        "& .editContainer": {
          display: "flex",
          alignItems: "center",
          "& img": { height: "24px", width: "24px" },
          "& .editText": {
            fontFamily: theme.fontFamily.MARKPRO,
            fontSize: "12px",
            color: theme.colors.DodgerBlue,
            textDecoration: "underline",
            cursor: "pointer",
          },
        },
      },
      "& .information": {
        display: "flex",
        // alignItems: "center",
        "& .left": { flex: "0.5" },
        "& .right": {
          flex: "0.5",
          "& .title": {
            fontSize: "13px",
            fontFamily: theme.fontFamily.MARKPROHEAVY,
            opacity: 0.3,
            padding: "10px 0px",
          },
        },
        "& .flexContainer": {
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          "& .label": { flex: "0.5", fontFamily: theme.fontFamily.MARKPRO },
          "& .val": {
            flex: "0.5",
            fontFamily: theme.fontFamily.MARKPROBOLD,
            "& .currency": {
              fontSize: "8px",
              position: "relative",
              bottom: "3px",
              marginRight: "2px",
            },
          },
          "& .changeLink": {
            color: theme.colors.DodgerBlue,
            marginLeft: "5px",
            display: "none",
          },
        },
      },
    },
  },
}));

function EmployeeDetail({
  setEditEmployeeDetail,
  employeeDetail,
  updateEmployeeMethod,
  createPayStackProfileActionMethod,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getEmployeeInfoMethod = useCallback(
    (data) => dispatch(getEmployeeInfoAction(data)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [confirmSwitch, setConfirmSwitch] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [switchRecord, setSwitchRecord] = useState(null);
  const [switchMethod, setSwitchMethod] = useState(null);

  useEffect(() => {
    if (
      confirmation === "yes" &&
      switchRecord &&
      switchRecord.id &&
      switchMethod
    ) {
      if (switchMethod === "updateEmployeeMethod")
        updateEmployeeMethod(
          {
            is_active: !switchRecord.is_active,
          },
          switchRecord.id
        );
      setSwitchRecord(null);
      setSwitchMethod(null);
      setConfirmation(null);
    }
  }, [confirmation]);

  useEffect(() => {
    if (companyDashboardReducer?.updateEmployeeSuccess && employeeDetail?.id) {
      getEmployeeInfoMethod(employeeDetail.id);
    }
  }, [
    companyDashboardReducer,
    setEditEmployeeDetail,
    employeeDetail,
    getEmployeeInfoMethod,
  ]);

  useEffect(() => {
    if (employeeDetail?.id) {
      getEmployeeInfoMethod(employeeDetail.id);
    }
  }, [employeeDetail, getEmployeeInfoMethod]);

  return dashboardReducer?.employeeInfo ? (
    <div className={classes.detailContainer}>

      {dashboardReducer?.error?.length ||
        companyDashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {dashboardReducer?.error?.length
            ? dashboardReducer.error.map((each, index) => (
              <div key={index}>{each}</div>
            ))
            : null}

          {companyDashboardReducer?.error?.length
            ? companyDashboardReducer.error.map((each, index) => (
              <div key={index}>{each}</div>
            ))
            : null}
        </div>
      ) : ""}

      <div className="block">
        <div className="header">
          <div className="employeeName">
            {dashboardReducer?.employeeInfo?.first_name}{" "}
            {dashboardReducer?.employeeInfo?.last_name}
          </div>
          <div className="editContainer">
            <div className="editContainer">
              <div
                className="editContainer" style={{ marginRight: "10px" }}
                onClick={() => {
                  createPayStackProfileActionMethod({
                    employee_id: employeeDetail.id,
                    company_id: dashboardReducer?.companyInfo?.id
                  });
                }}
              >
                <img src="/images/add.svg" style={{ height: "15px" }} alt="editIcon" />
                <span className="editText">Create PayStack profile</span>
              </div>
            </div>
            {setEditEmployeeDetail ? (
              <div
                className="editContainer"
                onClick={() => {
                  setEditEmployeeDetail(dashboardReducer.employeeInfo);
                }}
              >
                <img src="/images/editIcon.png" alt="editIcon" />
                <span className="editText">Edit</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="information">
          <div className="left">
            <div className="flexContainer">
              <div className="label">Employee</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.first_name}{" "}
                {dashboardReducer?.employeeInfo?.last_name}
                <a href="#" className="changeLink">
                  Change
                </a>
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Employee ID</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.employee_id || "NULL"}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Email</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.email}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Phone Number</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.profile?.phone}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Gender</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.gender
                  ? dashboardReducer.employeeInfo.gender === "F"
                    ? "Female"
                    : dashboardReducer.employeeInfo.gender === "M"
                      ? "Male"
                      : ""
                  : ""}
              </div>
            </div>
            {
              (!dashboardReducer?.employeeInfo?.country || dashboardReducer?.employeeInfo?.country == "NG") ? (
                <><div className="flexContainer">
                  <div className="label">NIN</div>
                  <div className="val" style={{ padding: "10px 0px" }}>
                    {dashboardReducer?.employeeInfo?.profile?.nin?.length
                      ? dashboardReducer.employeeInfo.profile?.nin
                      : null}
                  </div>
                </div>
                  <div className="flexContainer">
                    <div className="label">BVN</div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      {dashboardReducer?.employeeInfo?.profile?.bvn?.length
                        ? dashboardReducer.employeeInfo.profile?.bvn
                        : null}
                    </div>
                  </div></>
              ) : null
            }
            <div className="flexContainer">
              <div className="label">Account</div>
              <div className="val">
                <span style={{ fontSize: "12px" }}>Activated</span>
                <CommonSwitch
                  name={dashboardReducer?.employeeInfo?.id}
                  checked={dashboardReducer?.employeeInfo?.is_active}
                  disabled={!updateEmployeeMethod}
                  onClick={(e) => {
                    if (
                      dashboardReducer?.employeeInfo?.id &&
                      updateEmployeeMethod
                    ) {
                      e.stopPropagation();
                      setConfirmSwitch(true);
                      setSwitchRecord(dashboardReducer.employeeInfo);
                      setSwitchMethod("updateEmployeeMethod");
                    }
                  }}
                />
              </div>
            </div>
            {" "}
            <div className="flexContainer">
              <div className="label">Job Title</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.profile?.position}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Monthly Pay</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {dashboardReducer?.companyInfo?.currency ||
                    loginReducer?.companyInformation?.currency}
                </span>{" "}
                {moneyValue(dashboardReducer?.employeeInfo?.profile?.salary)}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Current Balance On Limit</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {dashboardReducer?.companyInfo?.currency ||
                    loginReducer?.companyInformation?.currency}
                </span>{" "}
                {moneyValue(dashboardReducer?.employeeInfo?.current_balance)}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Salary Withdrawal Limit</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {dashboardReducer?.companyInfo?.currency ||
                    loginReducer?.companyInformation?.currency}
                </span>{" "}
                {moneyValue(dashboardReducer?.employeeInfo?.withdrawal_limit)}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Savings</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {dashboardReducer?.companyInfo?.currency ||
                    loginReducer?.companyInformation?.currency}
                </span>{" "}
                {moneyValue(dashboardReducer?.employeeInfo?.profile?.savings)}
              </div>
            </div>
            {" "}
            {/* <div className="flexContainer">
              <div className="label">Leaves</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>
                  {dashboardReducer?.companyInfo?.currency ||
                    loginReducer?.companyInformation?.currency}
                </span>{" "}
                {dashboardReducer?.employeeInfo?.profile?.leaves
                  ? Math.round(
                      dashboardReducer?.employeeInfo?.profile?.leaves * 1000
                    ) / 1000
                  : 0}
              </div>
            </div> */}
            {/* {dashboardReducer?.employeeInfo?.deductions?.length
              ? dashboardReducer.employeeInfo.deductions.map((each) => (
                  <div className="flexContainer" key={each.id}>
                    <div className="label">{each.name}</div>
                    <div className="val" style={{ padding: "10px 0px" }}>
                      <span className={"currency"}>
                        {dashboardReducer?.companyInfo?.currency ||
                          loginReducer?.companyInformation?.currency}
                      </span>{" "}
                      {each.amount ? Math.round(each.amount * 1000) / 1000 : 0}
                    </div>
                  </div>
                ))
              : null} */}
          </div>
          <div className="right">
            <div className="title">Bank Information</div>
            <div className="flexContainer">
              <div className="label">Account Holder Name</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].account_holder
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Bank Name</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].name
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Bank Code</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].code
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Paystack ID</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].paystack_id
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Recipient Code</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].recipient_code
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Account Number</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].number
                  : null}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">IBAN</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {dashboardReducer?.employeeInfo?.banks?.length
                  ? dashboardReducer.employeeInfo.banks[0].iban
                  : null}
              </div>
            </div>
            {dashboardReducer?.employeeInfo?.branch && <>
              <div className="title">Office Location</div>
              <div className="flexContainer">
                <div className="label">Name</div>
                <div className="val" style={{ padding: "10px 0px" }}>
                  {dashboardReducer.employeeInfo.branch}
                </div>
              </div>
            </>}

          </div>
        </div>
      </div>
      <TransactionHistory employeeDetail={employeeDetail} />
      <ConfirmSwitchModal
        openModal={confirmSwitch}
        setConfirmation={(confirmation) => {
          setConfirmSwitch((temp) => !temp);
          setConfirmation(confirmation);
        }}
      />
    </div>
  ) : null;
}

export default EmployeeDetail;
